/*
  *公共样式变量
  bill_yang 2021.06
*/
@use "sass:math";
// 字体大小
$font_size_S: 12px;
$font_size_M: 14px;
$font_size_L: 16px;
$font_size_XL: 18px;
$font_size_XXL: 20px;

// 文字颜色
$color_theme: #0063DB;

// 背景颜色
$bgck-light-blue: #17c7ff;
$bgck-light-orange: #FF8E65;
$bgck-light-green: #2DCA73;
$bgck-light-purple: #6F5BF8;
$bgck-light-yellow: #F7C102;
$bgck-light-red: #FF4E4B;
$bgck-airtaxi: #0063DB;
$bgck-light-gray: #DFE7F5;
$bgck-modal: #30354F;
$bg-theme:#07080A;
$bg-results:#0d1123;
$bg-flights:#1A2243;
$bg-prompt:#0E142D;
$bg-map:rgba(13, 17, 35, 0.85);

//vw适配方法
@function vw($px) {
  @return math.div($px, 375) * 100vw;
}


@mixin flex($justify:flex-start,$align:stretch) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
}


/*
  *公共样式
  bill_yang 2021.06
*/
@import '../style/index.scss';

iframe {
  display: none;
}

@font-face {
  font-family: 'Genuine';
  src: url('../FontWeb/Genuine/Genuine.ttf');
}

.bg-image {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

* {
  font-family: Inter, -apple-system, BlinkMacSystemFont, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei',
    'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  // letter-spacing: vw(1);
}

#root,
body,
html {
  /* Text Colors */
  --color-theme: #0063db;

  /* Background Colors */
  --bgck-light-blue: #17c7ff;
  --bgck-light-orange: #ff8e65;
  --bgck-light-green: #2dca73;
  --bgck-light-purple: #6f5bf8;
  --bgck-light-yellow: #f7c102;
  --bgck-light-red: #ff4e4b;
  --bgck-light-white: #ffffff;
  --bgck-airtaxi: #0063db;
  --bgck-light-gray: #dfe7f5;
  --bgck-modal: #30354f;
  --bg-theme: #07080a;
  --bg-results: #0d1123;
  --bg-flights: #1a2243;
  --bg-prompt: #0e142d;
  --bg-hover: #dfe7f5;
  --bg-map: rgba(13, 17, 35, 0.8);

  --fc-black: #07080a;
  --fw-normal: 400;
  --fw-bold: 700;

  --fs-300: 12px;
  --fs-400: 14px;
  --fs-500: 16px;
  --fs-600: 18px;
  --fs-700: 20px;
  --fs-800: 24px;
  --fs-900: 36px;
  --fs-1000: 48px;

  @media (max-width: 938px) {
    --fs-800: 20px;
    --fs-900: 28px;
    --fs-1000: 40px;
  }

  @media (max-width: 757px) {
    --fs-500: 12px;
    --fs-600: 12px;
    --fs-700: 14px;
    --fs-800: 16px;
    --fs-900: 18px;
    --fs-1000: 28px;
  }
  height: 100%;
}

body {
  background-color: #ffffff;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.2);
}

input::-moz-input-placeholder {
  color: rgba(0, 0, 0, 0.2);
}

input::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.2);
}
// :not()
img {
  max-width: 100%;
  display: block;
  border: none;
}

ul,
li {
  list-style: none;
}

b {
  font-weight: 700;
}

i {
  font-style: normal;
}

a {
  color: #07080a;
}

button {
  border: none;
  outline: none;
  background-color: transparent;
}

.fadeout {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  opacity: 0;
}

.fadein {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  opacity: 1;
}

.isNot {
  display: none !important;
}

.isShow {
  display: block;
}

.max-width {
  width: vw(1300);
  height: 100%;
  margin: 0 auto;
}

.forbid {
  transform: translate3d(0px, 0px, 0px) !important;
}

.swiper-width {
  // width: vw(1500);
  height: 100%;
  margin: 0 auto;
}

.public-flex {
  display: flex;
  justify-content: space-between;
}

.public-algin-center {
  align-items: center;
}

.no-data {
  overflow: hidden;
  min-height: vw(200);

  > img {
    width: vw(152);
    height: vw(108);
    margin: vw(64) auto 0 auto;
  }

  > p {
    color: rgba(13, 17, 35, 0.2);
    font-size: vw(14);
    font-weight: 700;
    line-height: vw(20);
    text-align: center;
    margin-top: vw(24);
    padding: 0 vw(16);
  }
}

.grey {
  color: #000 !important;
}

.date-selected {
  color: #fff !important;
}

.disable {
  color: #ddd !important;
}

.animation-in {
  animation-name: fadein;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.animation-left {
  animation-name: fadeleft;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.animation-right {
  animation-name: faderight;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.animation-height {
  animation-name: height;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

// 从上至下动画效果
@keyframes fadein {
  0% {
    opacity: 0;
    transform: translate3d(0, vw(200), 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

// 从右至左动画效果
@keyframes fadeleft {
  0% {
    opacity: 0;
    transform: translate3d(vw(500), 0, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

// 从右至左动画效果
@keyframes faderight {
  0% {
    opacity: 0;
    transform: translate3d(vw(-500), 0, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

// 放大缩小动画效果
@keyframes pulse {
  0% {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

// 放大缩小动画效果
@keyframes height {
  0% {
    height: 0;
    // opacity: 0;
  }

  100% {
    height: vw(352);
    // opacity: 1;
  }
}

.model-box {
  // 弹框
  .am-modal-content {
    background-color: transparent !important;
  }
}

.am-picker {
  background-color: #fff;
}

// 首页搜索弹框
.public-modal {
  background-color: $bgck-modal;
  border-radius: vw(16) vw(16) 0 0;
  padding: vw(24) vw(16);
  background-image: url(../imgs/webp/bg_introduce.webp);

  .modal-title {
    font-size: vw(14);
    font-weight: 700;
    text-align: center;
    color: #fff;
    margin-bottom: vw(24);
    font-style: italic;
  }

  .address {
    border-radius: vw(8);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    color: rgb(187, 187, 187);

    &::before {
      content: '';
      width: vw(225);
      height: vw(1);
      right: 0;
      background-color: rgba(0, 0, 0, 0.2);
      position: absolute;
      top: vw(40);
      left: vw(54);
      // margin-left: vw(-112.5);
    }

    .reverse {
      width: vw(48);
      height: vw(48);
      background-size: vw(25);
      background-position: center;
      position: absolute;
      background-image: url(../imgs/webp/reverse.webp);
      right: vw(0);
      top: 50%;
      margin-top: vw(-24);
    }

    .form-to {
      width: 100%;
      height: vw(40);
      background-color: #fff;
      text-align: left;
      padding-left: vw(16);
      display: flex;
      align-items: center;
      padding-right: vw(60);

      .search-icon {
        width: vw(24);
        height: vw(24);
        margin-right: vw(16);
        flex-shrink: 0;

        &.from-icon {
          background-image: url(../imgs/webp/from.webp);
        }

        &.to-icon {
          background-image: url(../imgs/webp/to.webp);
        }
      }

      input {
        color: #000;
        background-color: #fff;
        font-size: vw(14);
        width: 100%;
        height: vw(40);
        line-height: vw(40);
        border: none;
        outline: none;
      }
    }
  }

  .date-month {
    width: 100%;
    height: vw(40);
    background-color: #fff;
    border-radius: vw(8);
    margin-top: vw(16);
    text-align: left;
    padding-left: vw(16);
    display: flex;
    align-items: center;
    overflow: hidden;

    .search-icon {
      width: vw(24);
      height: vw(24);
      margin-right: vw(16);
      flex-shrink: 0;

      &.date-icon {
        background-image: url(../imgs/webp/date.webp);
      }
    }

    > input {
      color: #000;
      font-size: vw(14);
      height: 100%;
      width: 100%;
      border: none;
      outline: none;
    }
  }

  .am-button {
    width: 100%;
    height: vw(40);
    line-height: vw(40);
    color: #fff;
    border-radius: vw(8);
    font-size: vw(20);
    font-weight: 700;
    background-image: url(../imgs/webp/banner_btn.webp);
    text-decoration: none;
    margin-top: vw(16);

    &::before {
      border: none !important;
    }

    &::after {
      content: '';
      display: block;
      width: vw(24);
      height: vw(24);
      background-image: url('../imgs/webp/seach_modal.webp');
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      left: vw(16);
      top: vw(8);
    }
  }
}

// 日历弹框
.calendar-modal {
  .current-day {
    background: linear-gradient(94.57deg, #0063db 0%, #17c7ff 100%) !important;
  }

  .cancel-confirm {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    padding: vw(16) vw(16) 0 vw(16);

    .am-button {
      width: vw(80);
      height: vw(30);
      line-height: vw(30);
      font-size: vw(16);
      border: none;
      font-weight: 700;
      color: rgba(0, 0, 0, 0.3);

      &::before {
        display: none;
      }

      & + .am-button {
        color: $bgck-airtaxi;
      }
    }
  }
}

.loading {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;

  &.pay {
    background-color: #fff;
    color: $bgck-light-red;
  }

  .text {
    position: absolute;
    top: 50%;
    margin-top: vw(-160);
    font-size: vw(16);
    font-weight: 700;

    p {
      text-align: center;
      margin: vw(4) 0;
    }
  }
}

// 非移动端打开页面
.prompt-mobile {
  width: 100%;
  height: 100vh;
  background-color: $bg-theme;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    color: #fff;
    font-size: vw(14);
  }
}

.img-icon {
  width: 300px !important;
}

.am-modal-button-group-h .am-modal-button:first-child {
  color: #108ee9 !important;
}

.transition {
  width: 100vh;
  height: 100vh;
  background-color: #000;
}

// 优惠卷组件
.coupons-item {
  width: vw(344);
  height: vw(100);
  display: flex;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  background-position: right;
  border-radius: vw(8);

  & + .coupons-item {
    margin-top: vw(16);
  }

  .coupons-left {
    width: vw(124);
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      i {
        display: block;
        width: vw(92);
        font-style: normal;
        text-align: center;

        &:nth-child(1) {
          font-size: vw(18);
          font-weight: 700;
        }

        &:nth-child(2) {
          font-size: vw(9);
          // padding: 0 vw(8);

          > span {
            display: block;
          }
        }
      }

      .use-button {
        display: flex;
        width: vw(92);
        height: vw(22);
        align-items: center;
        justify-content: center;
        font-size: vw(12);
        font-weight: 700;
        border-radius: vw(4);
        background-color: rgba(255, 255, 255, 0.2);
        color: #fff;
        margin: vw(4) auto 0 auto;
      }
    }
  }

  .coupons-right {
    flex: 1;
    line-height: vw(13);
    font-size: vw(12);
    text-align: left;
    padding: 0 vw(16);

    p {
      font-weight: 700;
      height: vw(50);
      padding-top: vw(8);
      max-width: vw(162);
      white-space: pre-wrap;
    }

    span {
      display: block;
      margin: vw(7) 0 vw(4) 0;
      white-space: nowrap;
      font-size: vw(9);
    }

    i {
      width: vw(186);
      border-top: 2px solid rgba(255, 255, 255, 0.2);
      padding-top: vw(4);
      font-size: vw(9);
      display: block;
      font-style: normal;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &.disable-used-voucher {
    background-image: url(../imgs/svg/coupon_voucher_disable.svg);
  }

  &.disable-used-discount {
    background-image: url(../imgs/svg/coupon_discount_disable.svg);
  }

  &.iscount {
    background-image: url(../imgs/svg/coupons_zk.svg);
  }

  &.deduction {
    background-image: url(../imgs/svg/coupons_mj.svg);
  }

  &.dissatisfy-pay-zk {
    background-image: url(../imgs/svg/coupons_zk.svg);
  }

  &.dissatisfy-pay-mj {
    background-image: url(../imgs/svg/coupons_mj.svg);
  }

  &.iscount-used {
    background-image: url(../imgs/svg/coupons_zk_gq.svg);
    color: rgba(0, 0, 0, 0.2);

    .coupons-right {
      i {
        border-top: 2px solid rgba(0, 0, 0, 0.2);
      }
    }
  }

  &.dissatisfy-zk {
    color: rgba(0, 0, 0, 0.2);
    background-image: url(../imgs/svg/coupons_zk_gq.svg);

    .coupons-right {
      i {
        border-top: 2px solid rgba(0, 0, 0, 0.2);
      }
    }
  }

  &.dissatisfy-mj {
    color: rgba(0, 0, 0, 0.2);
    background-image: url(../imgs/svg/coupons_mj_gq.svg);

    .coupons-right {
      i {
        border-top: 2px solid rgba(0, 0, 0, 0.2);
      }
    }
  }

  &.deduction-used {
    background-image: url(../imgs/svg/coupons_mj_gq.svg);
    color: rgba(0, 0, 0, 0.2);

    .coupons-right {
      i {
        border-top: 2px solid rgba(0, 0, 0, 0.2);
      }
    }
  }

  &.notUse {
    color: rgba(0, 0, 0, 0.2);
    background-image: url(../imgs/svg/coupons_mj_gq.svg);

    .coupons-right {
      i {
        border-top: 2px solid rgba(0, 0, 0, 0.2);
      }
    }
  }

  .not-coupons {
    width: vw(72);
    height: vw(72);
    position: absolute;
    right: vw(86);
    top: 50%;
    margin-top: vw(-36);
    transform: rotate(30deg);

    &.used {
      background-image: url(../../assets/imgs/svg/used.svg);
    }

    &.over {
      background-image: url(../../assets/imgs/svg/over.svg);
    }
  }
}

.coupons {
  background-color: #ffffff;
  color: #fff;
  overflow: hidden;
  height: 100%;
  // min-height: 100vh;

  .details-tilte {
    display: flex;
    align-items: center;
    margin: vw(16) 0 vw(16) vw(16);

    > img {
      width: vw(24);
      height: vw(24);
      margin-right: vw(16);
    }
  }

  .coupons-title {
    font-size: vw(14);
    font-weight: 700;
    color: #07080a;
    text-align: left;
  }

  .coupons-content {
    max-height: vw(500);
    overflow-y: scroll;
    padding: 0 vw(16);

    .pay_coupon_list {
      margin-bottom: vw(16);
    }

    .disable-wain {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      font-weight: 500;
      font-size: vw(10);
      line-height: vw(12);
      color: #07080a;
      text-align: left;
      margin-top: vw(4);

      i {
        color: #ff4e4b;
      }
    }

    .coupons-list {
      border: vw(2) solid transparent;
      border-radius: vw(12);

      &.active {
        border-color: #07080a;
        overflow: hidden;
      }
    }

    .list-coupons-item {
      display: flex;
      flex-direction: column;
      overflow: hidden;

      &:last-child {
        margin-bottom: 0;
      }

      > i {
        width: vw(16);
        height: vw(16);
        border-radius: vw(4);
        border: vw(2) solid rgba(0, 0, 0, 0.2);
        margin-left: vw(8);

        &.active {
          border: none;
          background-image: url(../imgs/svg/checked.svg);
        }
      }
    }

    .coupons-line {
      height: vw(1);
      background-color: rgba(7, 19, 33, 0.08);
      margin: vw(8) 0;
    }

    .dissatisfy-why {
      padding-left: vw(32);
      font-size: vw(9);
      text-align: left;
      position: relative;
      margin: vw(8) 0;
      color: #07080a;

      &::before {
        content: '';
        display: block;
        width: vw(24);
        height: vw(24);
        background-image: url(../imgs/svg/remind_error.svg);
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        left: vw(2);
        top: 50%;
        margin-top: vw(-12);
      }

      span {
        color: $bgck-light-red;
      }
    }
  }

  .confirm-coupons {
    width: vw(343);
    height: vw(40);
    border-radius: vw(8);
    font-size: vw(14);
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 0 auto;
    background-image: url(../../assets/imgs/webp/bg_btn.webp);
    position: absolute;
    bottom: vw(24);
    left: 0;
    right: 0;
  }
}

.profile-coupons {
  overflow-y: auto;

  .coupons-content {
    max-height: 100%;
    overflow: hidden;

    .coupons-list {
      margin-bottom: vw(16);
    }
  }
}

// 第三方日历组件
.hash-calendar-box {
  .cancel-confirm {
    width: 100%;
    position: absolute;
    left: 0;
    top: vw(-5);
    z-index: 22;
    background-color: transparent;
  }

  .hash-calendar {
    .calendar_content {
      .calendar_title {
        display: block;

        .calendar_title_date {
          text-align: center;
        }

        .calendar_confirm {
          display: none;
        }
      }

      .calendar_week {
        .calendar_day {
          color: $bgck-light-green;
        }
      }

      .calendar_body {
        .calendar_group {
          .calendar_item_disable {
            background-color: #fff !important;

            .calendar_day {
              color: rgba(0, 0, 0, 0.2) !important;

              &.calendar_day_today {
                background-color: #fff !important;
              }
            }
          }
          .calendar_day {
            color: #000;

            &.calendar_day_checked {
              font-weight: 700;
              color: #fff !important;
              background: #0063db;
            }

            &.calendar_day_not {
              color: rgba(0, 0, 0, 0.2);
              opacity: 0;

              & + div.calendar_dot {
                opacity: 0;
              }
            }
          }

          .calendar_item_disable {
            .calendar_day {
              color: rgba(0, 0, 0, 0.2);
            }
          }
        }
      }
    }
  }
}

// 两端对齐
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// 面包屑
.breadcrumb {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  > li {
    &:not(:first-child)::before {
      content: '';
      width: vw(16);
      height: vw(12);
      display: inline-block;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      background-image: url('../imgs/svg/breadcrumb_disabled.svg');
      vertical-align: middle;
      margin: 0 vw(16);
    }

    &.actived {
      &:not(:first-child)::before {
        background-image: url('../imgs/svg/breadcrumb_actived.svg');
      }

      > span {
        color: #07080a;
      }
    }

    &.currentActived {
      > span {
        font-weight: 700;
      }
    }

    > span {
      font-weight: 400;
      font-size: vw(12);
      line-height: vw(15);
      color: rgba(7, 8, 10, 0.5);
    }
  }
}

.air-swiper-container {
  .swiper-button-prev,
  .swiper-button-next {
    background: url(../imgs/svg/prev.svg) no-repeat top left/100% 100%;
    width: vw(24);
    height: vw(24);
    top: 52%;

    &::after {
      content: '';
      background-image: none;
      width: vw(24);
    }
  }

  .swiper-button-prev {
    left: vw(16);
  }

  .swiper-button-next {
    transform: rotate(180deg);
    right: vw(16);
  }

  .swiper-button-disabled {
    display: none;
  }
}

// 机型图片
.air-swiper-picture {
  .swiper-slide {
    > img {
      width: vw(160);
      height: vw(100);
      object-fit: cover;
      border-radius: vw(8);
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: none;
    background: url(../imgs/svg/prev.svg) no-repeat top left/100% 100%;
    width: vw(24);
    height: vw(24);
    top: 60%;

    &::after {
      content: '';
      background-image: none;
      width: vw(24);
    }
  }

  .swiper-button-next {
    transform: rotate(180deg);
  }

  &.air-swiper-picture-navigation {
    .swiper-button-prev,
    .swiper-button-next {
      display: block;
    }
  }
}

.container_ {
  padding-top: vw(44);
}

.search-calendar-box {
  .calendar_dot {
    width: 30px !important;
    height: 30px !important;
    border-radius: 50% !important;
    position: absolute;
    z-index: -1;
    transform: translate(0, -1px) !important;
    font-weight: 400;
    font-size: 12px;
    color: #0063db;
  }
}
